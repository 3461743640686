import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Inner } from "src/components/shared/Layout"
import Typography from "src/components/shared/Typography"
import Button from "src/components/shared/Button"
import Link from "src/components/shared/Link"
import styles from "./styles.module.css"
import Star from "./Star"
import OpinionCard from "./OpinionCard"
import Turtle from "src/images/tortue.svg"

const opinionQuery = graphql`
  query OpinionTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "home-opinion" } }) {
      html
      frontmatter {
        title
        score
        firstOpinion
        firstAuthor
        secondOpinion
        secondAuthor
        thirdOpinion
        thirdAuthor
      }
    }
  }
`

export const OpinionTemplate = ({
  preview,
  title,
  score,
  firstOpinion,
  firstAuthor,
  secondOpinion,
  secondAuthor,
  thirdOpinion,
  thirdAuthor,
}) => (
  <section>
    <Inner id="opinion">
      <section className={styles.Opinion}>
        <Typography.h1 className={styles.Title}>{title}</Typography.h1>

        <div className={styles.Stars}>
          {[1, 2, 3, 4, 5].map(() => (
            <Star />
          ))}
        </div>
        <div className={styles.Score}>
          <Link target="_blank" href="https://www.facebook.com/TurtleTonic">
            {score}
          </Link>
        </div>

        <div className={styles.Line1Opinions}>
          <OpinionCard citation={firstOpinion} author={firstAuthor} />
          <OpinionCard citation={secondOpinion} author={secondAuthor} />
        </div>
        <div className={styles.Line2Opinions}>
          <OpinionCard citation={thirdOpinion} author={thirdAuthor} />
          <img src={Turtle} alt="" />
        </div>
        <div className={styles.Buttons}>
          <Button
            target="_blank"
            href="https://www.facebook.com/pg/TurtleTonic/reviews"
          >
            Je donne mon avis
          </Button>
        </div>
      </section>
    </Inner>
  </section>
)

const Opinion = () => {
  const data = useStaticQuery(opinionQuery)
  const {
    markdownRemark: {
      frontmatter: {
        title,
        score,
        firstOpinion,
        firstAuthor,
        secondOpinion,
        secondAuthor,
        thirdOpinion,
        thirdAuthor,
      },
    },
  } = data

  return (
    <OpinionTemplate
      title={title}
      score={score}
      firstOpinion={firstOpinion}
      firstAuthor={firstAuthor}
      secondOpinion={secondOpinion}
      secondAuthor={secondAuthor}
      thirdOpinion={thirdOpinion}
      thirdAuthor={thirdAuthor}
    />
  )
}

export default Opinion
