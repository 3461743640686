import React from "react"
import styles from "./index.styles.module.css"
import Layout, { Inner } from "src/components/shared/Layout"
import Typography from "src/components/shared/Typography"
import SEO from "src/components/shared/seo"

import Presentation from "src/components/homepage/Presentation"
import About from "src/components/homepage/About"
import Center from "../components/homepage/Center"
import Opinion from "../components/homepage/Opinion"

const IndexPage = () => (
  <Layout withIllustration withoutHeader white>
    <SEO title="Turtle Tonic — Aquabike • Aquagym • Leçon de natation" />
    <Presentation />
    <Center />
    <About />
    <Opinion />
  </Layout>
)

export default IndexPage
