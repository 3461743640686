import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "src/components/shared/Header"
import Typography from "src/components/shared/Typography"
import Button from "src/components/shared/Button"
import { Inner } from "src/components/shared/Layout"
import styles from "./styles.module.css"
import ArrowSeparator from "src/components/shared/ArrowSeparator"
import LogoPresentation from "src/images/logoPresentation.png"

const presentationQuery = graphql`
  query PresentationTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "home-presentation" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`

export const PresentationTemplate = ({ preview, title, html }) => (
  <section className={styles.Presentation}>
    <Inner>
      <Header white />

      <div className={styles.Intro}>
        <Typography.h1 className={styles.Header}>{title}</Typography.h1>
        {!!preview ? (
          <Typography.p primary>{html}</Typography.p>
        ) : (
          <div
            className={styles.Paragraph}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )}
        <div className={styles.Buttons}>
          <Button href="/reservation">Réserver un cours</Button>
          <Button href="/cours" className={styles.BorderedButton}>
            Tous les cours
          </Button>
        </div>
      </div>

      <img
        src={LogoPresentation}
        alt="Turtle Tonic"
        className={styles.FloatIllu}
      />

      <ArrowSeparator to="center" />
    </Inner>
  </section>
)

const Presentation = () => {
  const data = useStaticQuery(presentationQuery)
  const {
    markdownRemark: {
      frontmatter: { title },
      html,
    },
  } = data

  return <PresentationTemplate title={title} html={html} />
}

export default Presentation
