import React from "react"

const Wave = () => (
  <svg
    version="1.1"
    id="star_turtle_tonic"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 489.8"
  >
    <path
      class="st0"
      d="M510.6,185c-3.9-11.9-15.4-20.1-31.7-22.5l-132.5-19.3L287.2,23.2C279.9,8.5,268.5,0,256,0s-23.9,8.5-31.2,23.2
	l-59.3,120.1L33,162.5C16.8,164.9,5.2,173.1,1.4,185s0.6,25.4,12.4,36.8l95.9,93.5L87,447.3c-2.9,16.9,2,27,6.7,32.5
	c5.5,6.5,13.4,10,22.4,10c6.8,0,14-2,21.4-5.9L256,421.6l118.5,62.3c7.4,3.9,14.6,5.9,21.4,5.9h0c9,0,16.9-3.6,22.4-10
	c4.6-5.5,9.6-15.6,6.7-32.5l-22.6-132l95.9-93.5C510,210.4,514.5,196.9,510.6,185z"
    />
  </svg>
)

export default Wave
