import React from "react"
import styles from "./styles.module.css"
import { Link as ScrollLink } from "react-scroll"
import Arrow from "./icon-arrow.svg"

const ArrowSeparator = ({ to }) => (
  <ScrollLink className={styles.ArrowContainer} to={to} smooth={true}>
    <img src={Arrow} alt="Scroll" />
  </ScrollLink>
)

export default ArrowSeparator
