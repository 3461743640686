import React from "react"
import styles from "./styles.module.css"
import Quote from "src/images/quote.svg"

const OpinionCard = ({ citation, author }) => {
  return (
    <div className={styles.OpinionCard}>
      {citation}
      <div className={styles.Author}>
        <img src={Quote} alt="par" /> {author}
      </div>
    </div>
  )
}

export default OpinionCard
