import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Illustration from "src/components/shared/Illustration"
import Typography from "src/components/shared/Typography"
import Button from "src/components/shared/Button"
import { Inner } from "src/components/shared/Layout"
import styles from "./styles.module.css"

const centerQuery = graphql`
  query CenterTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "home-center" } }) {
      html
      frontmatter {
        title
        firstImage
        secondImage
      }
    }
  }
`

export const CenterTemplate = ({
  preview,
  title,
  firstImage,
  secondImage,
  html,
}) => (
  <section>
    <Inner id="center">
      <section className={styles.Center}>
        <div className={styles.Intro}>
          <Typography.h1>{title}</Typography.h1>
          {!!preview ? (
            <Typography.p primary>{html}</Typography.p>
          ) : (
            <div
              className={styles.Paragraph}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          )}
          <Button href="/contact">Une question ?</Button>
        </div>

        <div className={styles.Illustrations}>
          <Illustration
            withWave
            waveClassName={styles.Wave}
            src={firstImage}
            height={350}
            imageClassName={styles.Image}
            className={styles.CenterFirstIllustration}
          />
          <Illustration
            src={secondImage}
            height={350}
            imageClassName={styles.Image}
            className={styles.CenterSecondIllustration}
          />
        </div>
      </section>
    </Inner>
  </section>
)

const Center = () => {
  const data = useStaticQuery(centerQuery)
  const {
    markdownRemark: {
      frontmatter: { title, firstImage, secondImage },
      html,
    },
  } = data

  return (
    <CenterTemplate
      title={title}
      firstImage={firstImage}
      secondImage={secondImage}
      html={html}
    />
  )
}

export default Center
