import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Typography from "src/components/shared/Typography"
import Illustration from "src/components/shared/Illustration"
import Button from "src/components/shared/Button"
import { Inner } from "src/components/shared/Layout"
import styles from "./styles.module.css"
import AmelieGeoffrey from "src/images/AmelieGeoffrey.png"

const aboutQuery = graphql`
  query AboutTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "home-about" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`

export const AboutTemplate = ({ preview, title, html }) => (
  <section>
    <Inner id="about">
      <section className={styles.About}>
        <Illustration
          withShadow
          withWave
          src={AmelieGeoffrey}
          height={550}
          className={styles.AboutIllustration}
        />
        <div className={styles.AboutText}>
          <Typography.h1>{title}</Typography.h1>
          {!!preview ? (
            <Typography.p primary>{html}</Typography.p>
          ) : (
            <div
              className={styles.Paragraph}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          )}
          <Button href="/planning">Voir le planning</Button>
        </div>
      </section>
    </Inner>
  </section>
)

const About = () => {
  const data = useStaticQuery(aboutQuery)
  const {
    markdownRemark: {
      frontmatter: { title },
      html,
    },
  } = data

  return <AboutTemplate title={title} html={html} />
}

export default About
